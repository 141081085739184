
//公共库
import axios from "axios";
import { Message } from "element-ui";
import { VueEditor } from "vue2-editor";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { addShopGiftconf, editShopGiftconf, getShopGiftconfList, getShopPropmodelconfList } from "@/api/request/item";

//组件
@Component({
  name: "ItemResConf",
  components: {
    VueEditor,
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private propList: any[] = [];
  private listLoading: boolean = false;

  //请求数据
  private listQuery: any = {
    row: 20,
    page: 1,

    //临时数据
    id_str: "",

    //查询条件
    id: 0, // 道具资源ID
    type: 0, // 道具资源类型
    name: "", // 道具资源名称
  };

  //创建
  created() {
    //获取道具类型属性
    this.getPropList();

    //获取列表
    this.getList();
  }

  ////获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.id = Number(this.listQuery.id_str);

    //获取数据
    const { data } = await getShopGiftconfList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具类型属性
  private async getPropList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getShopPropmodelconfList({});

    //数据赋值
    this.propList = GFunc.checkarray(data);
    this.propList.unshift({ id: 0, model_name: "请选择" });
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理创建
  private handleCreate(): void {
    //初始化表单
    this.addForm = {
      id: 0, //资源ID
      type: 0, //道具类型
      icon: "", //图标地址
      gifturl: "", // 资源动图地址
      ext: undefined, //气泡道具数据
      color: "#ffffff", //颜色

      //语言数据
      gift_name_list: { zh: "", en: "", id: "" },
      description_list: { zh: "", en: "", id: "" },
    };

    //数据初始化
    this.bgUrl = null;
    this.picUrl = null;
    this.gifUrl = null;
    this.bgFileList = [];
    this.picFileList = [];
    this.gifFileList = [];
    this.dialogStatus = "create";

    //显示对话框
    this.dialogVisible = true;
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据初始化
    this.bgUrl = null;
    this.picUrl = null;
    this.gifUrl = null;
    this.bgFileList = [];
    this.picFileList = [];
    this.gifFileList = [];
    if (GFunc.checkstring(row.icon)) {
      this.picUrl = GFunc.checkstring(row.icon);
      this.picFileList.push({ name: "name.jpg", url: GFunc.checkstring(row.icon) });
    }
    if (GFunc.checkstring(row.gifturl)) {
      this.gifUrl = GFunc.checkstring(row.gifturl);
      this.gifFileList.push({ name: "name.gif", url: GFunc.checkstring(row.gifturl) });
    }
    this.addForm = GFunc.deepClone(Object.assign({}, row));
    this.dialogStatus = "update";
    this.addForm.color = "#ffffff";

    //气泡数据
    if (row.type == 3000) {
      //数据赋值
      if (row.ext) {
        //数据赋值
        var extJson: any = JSON.parse(row.ext);

        //数据赋值
        this.bgUrl = extJson.bg_image;
        this.addForm.color = extJson.color;
        this.bgFileList.push({ name: "name.jpg", url: GFunc.checkstring(extJson.bg_image) });
      }
    }

    //显示对话框
    this.dialogVisible = true;
  }

  //获取道具类型名称
  private getPropName(id: number): string {
    //数据赋值
    id = GFunc.checknumber(id);
    const arr = GFunc.checkarray(this.propList);
    let prop = {} as any;
    for (let i = 0; i < arr.length; i++) {
      const tmp = arr[i] as any;
      if (id === tmp.id) {
        prop = tmp;
        break;
      }
    }

    //返回结果
    return prop ? prop.model_name : "--";
  }

  //获取道具名称
  private getGiftName(name: string): string {
    return GFunc.checkstring(name) ? name : "--";
  }

  //是否礼物URL
  private isGiftUploaded(gifturl: string): boolean {
    return !!GFunc.checkstring(gifturl);
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //添加表单
  private addForm: any = {
    id: 0, //资源ID
    type: 0, //道具类型
    icon: "", //图标地址
    gifturl: "", // 资源动图地址
    ext: undefined, //气泡道具数据
    color: "#ffffff", //颜色

    //语言数据
    gift_name_list: { zh: "", en: "", id: "" },
    description_list: { zh: "", en: "", id: "" },
  };

  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;
  private titleMap: any = { update: "修改道具资源", create: "新增道具资源" };

  //创建
  private async createData() {
    //判断显示
    if (this.addForm.type == 0) return Message({ message: "请选择道具类型！", type: "error", duration: 5 * 1000 });
    if (this.addForm.gift_name_list.zh == "") return Message({ message: "请输入道具资源名称(中文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.gift_name_list.en == "") return Message({ message: "请输入道具资源名称(英文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.gift_name_list.id == "") return Message({ message: "请输入道具资源名称(印尼)！", type: "error", duration: 5 * 1000 });

    //气泡道具判断
    if (this.addForm.type == 3000) {
      if (this.addForm.color == "") return Message({ message: "请输入气泡字体颜色", type: "error", duration: 5 * 1000 });
      if (this.bgUrl == "") return Message({ message: "请选择气泡背景图片", type: "error", duration: 5 * 1000 });
    }

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.addForm.id = GFunc.checkint(this.addForm.id);
    this.addForm.icon = GFunc.checkstring(this.picUrl);
    this.addForm.gifturl = GFunc.checkstring(this.gifUrl);
    this.addForm.type = GFunc.checkint(this.addForm.type);

    //气泡道具赋值
    if (this.addForm.type == 3000) {
      this.addForm.ext = JSON.stringify({ bg_image: this.bgUrl, color: this.addForm.color });
    } else {
      this.addForm.ext = undefined;
    }

    //添加资源
    await addShopGiftconf(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏对话框
    this.dialogVisible = false;

    //获取数据
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }

  //更新
  private async updateData() {
    //判断显示
    if (this.addForm.type == 0) return Message({ message: "请选择道具类型！", type: "error", duration: 5 * 1000 });
    if (this.addForm.gift_name_list.zh == "") return Message({ message: "请输入道具资源名称(中文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.gift_name_list.en == "") return Message({ message: "请输入道具资源名称(英文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.gift_name_list.id == "") return Message({ message: "请输入道具资源名称(印尼)！", type: "error", duration: 5 * 1000 });

    ///气泡道具判断
    if (this.addForm.type == 3000) {
      if (this.addForm.color == "") return Message({ message: "请输入气泡字体颜色", type: "error", duration: 5 * 1000 });
      if (this.bgUrl == "") return Message({ message: "请选择气泡背景图片", type: "error", duration: 5 * 1000 });
    }

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.addForm.id = GFunc.checkint(this.addForm.id);
    this.addForm.icon = GFunc.checkstring(this.picUrl);
    this.addForm.gifturl = GFunc.checkstring(this.gifUrl);
    this.addForm.type = GFunc.checkint(this.addForm.type);

    //气泡道具赋值
    if (this.addForm.type == 3000) {
      this.addForm.ext = JSON.stringify({ bg_image: this.bgUrl, color: this.addForm.color });
    } else {
      this.addForm.ext = undefined;
    }

    //编辑资源
    await editShopGiftconf(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏对话框
    this.dialogVisible = false;

    //获取数据
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //处理图片添加
  private async handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: Function) {
    //数据处理
    const formData = new FormData();
    formData.append("files", file);

    //上传图片
    var config: any = {};
    config.headers = this.getHeads;
    const res = await axios.post(this.getAction, formData, config);

    //数据赋值
    let url: string = res.data.data.images[0];

    //插入图片
    Editor.insertEmbed(cursorLocation, "image", url);

    //刷新编辑器
    resetUploader();
  }

  //--------------------------------- 图片处理 ---------------------------------
  //定义变量
  private bgUrl: any = null;
  private picUrl: any = null;
  private gifUrl: any = null;
  private bgFileList: any[] = [];
  private picFileList: any[] = [];
  private gifFileList: any[] = [];
  private getHeads = getHeaders();
  private getAction = this.getApi();

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //图片动画预览
  private handleBgPreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示对话框
    this.dialogImageVisible = true;
  }

  //图片动画预览
  private handlePicPreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示对话框
    this.dialogImageVisible = true;
  }

  //上传文件
  private handleBgBeforeUploadCommon(file: any): any {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 10;

    //文件过大
    if (!isLt2M) {
      //显示提示
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出10M",
        type: "warning",
        duration: 2000,
      });

      //返回十八
      return false;
    }
    //格式不正确
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //上传文件
  private handlePicBeforeUploadCommon(file: any): any {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 13;

    //文件过大
    if (!isLt2M) {
      //显示提示
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出13M",
        type: "warning",
        duration: 2000,
      });

      //返回十八
      return false;
    }
    //格式不正确
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除图片
  private handleBgRemove(file: any, fileList: any): void {
    this.bgUrl = null;
    this.bgFileList = [];
  }

  //移除图片
  private handlePicRemove(file: any, fileList: any): void {
    this.picUrl = null;
    this.picFileList = [];
  }

  //移除动画
  private handleGifRemove(file: any, fileList: any): void {
    this.gifUrl = null;
    this.gifFileList = [];
  }

  //上传图片成功
  private handleBgUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.bgFileList = fileList;
    this.bgUrl = response.data.images[0];
  }

  //上传图片成功
  private handlePicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picFileList = fileList;
    this.picUrl = response.data.images[0];
  }

  //上传动画成功
  private handleGifUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.gifFileList = fileList;
    this.gifUrl = response.data.images[0];
  }

  //--------------------------------- 显示图片 ---------------------------------
  //定义变量
  private dialogImageVisible = false;
  private dialogImageUrl: any = null;

  //打开图片
  private openImg(url: string) {
    //数据赋值
    this.dialogImageUrl = url;

    //显示界面
    this.dialogImageVisible = true;
  }
}
