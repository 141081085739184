import { render, staticRenderFns } from "./item_res_conf.vue?vue&type=template&id=be9ef844&scoped=true"
import script from "./item_res_conf.vue?vue&type=script&lang=ts"
export * from "./item_res_conf.vue?vue&type=script&lang=ts"
import style0 from "./item_res_conf.vue?vue&type=style&index=0&id=be9ef844&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be9ef844",
  null
  
)

export default component.exports